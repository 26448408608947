import dialogPolyfill from "dialog-polyfill"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    displayAutomatically: Boolean,
    removeOnClose: Boolean,
    stackName: String,
    alert: Boolean
  }

  renderer = {
    set: (target, property, value) => {
      target[property] = value

      if (property === "presenting") this.renderer.toggle()
      if (property === "heightScale") this.renderer.resize()

      return true
    },

    toggle: () => {
      this.state.presenting ? this.renderer.present() : this.renderer.dismiss()
    },

    present: () => {
      this.showAsModal()
      this.element.addEventListener("cancel", event => event.preventDefault())

      document.body.classList.add("element--no-overflow")

      this._emitEvent("dialog:present")
    },

    dismiss: () => {
      // In older browsers, element.close() throws:
      //   Failed to execute 'close' on dialog: The element does not have an 'open' attribute,
      //   and therefore cannot be closed.
      // when the dialog does not exist on the page
      try {
        this.element.classList.add(this._dialogCloseClassName)
        this.element.dataset.dataClosing = true

        setTimeout(() => {
          this.element.classList.remove(this._dialogCloseClassName)
          this.element.dataset.dataClosing = false
          this.element.close()

          document.body.classList.remove("element--no-overflow")

          this._emitEvent("dialog:dismiss")

          if (this.removeOnCloseValue) this.element.remove()
        }, 500)
      } catch(error) {
        console.log(error.message)
      }
    },

    resize: () => {
      this.element.style.setProperty("--dialog-height-scale", `${this.state.heightScale}px`)
    }
  }

  connect() {
    this.state = new Proxy({}, this.renderer)
    this._dialogCloseClassName = "dialog--closing"

    dialogPolyfill.registerDialog(this.element)

    this.element.addEventListener("click", this._handleBackdropClick.bind(this))

    if (this.displayAutomaticallyValue) this.open()
  }

  disconnect() {
    this.close()

    this.element.removeEventListener("click", this._handleBackdropClick.bind(this))
  }

  open(event) {
    if (event) event.preventDefault()

    this.state.presenting = true

    this.resize()
  }

  close(event) {
    if (event?.currentTarget?.getAttribute("href") === "#") event.preventDefault()
    this._dialogCloseClassName = event?.detail?.dialogCloseClassName || this._dialogCloseClassName

    this.state.presenting = false
  }

  resize(event) {
    this.state.heightScale = this.heightScale
  }

  _emitEvent(eventName) {
    this.element.dispatchEvent(this._buildEvent(eventName))

    if (this.stackNameValue) {
      this._emitStackEvent(eventName)
    }
  }

  _emitStackEvent(eventName) {
    window.dispatchEvent(this._buildEvent(
      `sheet-stack-${eventName}`,
      { targetDialog: this.element }
    ))
  }

  _buildEvent(eventName, data = {}) {
    const eventDetail = Object.assign(this.state, data)
    return new CustomEvent(eventName, {
      bubbles: false,
      detail: eventDetail
    })
  }

  _handleBackdropClick(event) {
    if(this.isAlertDialog) {
      return
    }

    if (event.target === this.element) {
      this.state.presenting = false
    }
  }

  showAsModal() {
    this.element.showModal()
    this.element.dataset.openedAsModal = true
  }

  get isAlertDialog() {
    return this.alertValue
  }

  get heightScale() {
    return window.innerHeight * 0.01
  }

  get backdropElement() {
    return document.querySelector(".dialog::backdrop") || document.querySelector(".dialog + .backdrop")
  }
}
